import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './dashmain.css'
import { useAuth } from '../../userAuth';
import CurrentGoalCenter from './currentGoalCenter';


const Content = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  return (
    <div className="contentCenter">
              {isLoggedIn && title === 'Center' && (
    <>
        <CurrentGoalCenter />
        </>
)}

    </div>
  );
};

const DashboardCenter = () => {
  const { isLoggedIn } = useAuth();
  const title = localStorage.title;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);
  return (
      <div className="dashmain">
        {isLoggedIn && title === 'Center' && (
    <>

        <div className="dashmaintoprow">
        </div>
        <Content />
        </>
)}

      </div>
  );
};

export default DashboardCenter;
